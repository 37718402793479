<template>
  <!-- 签到详情 -->
  <div class="registrationDetails">
    <van-nav-bar :title="$t('registrationDetails.RdTxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />
    <div class="overflow_div">
      <div class="registInfo">
        <img v-if="pxqdData.csurl != ''"
             v-lazy="pxqdData.csurl"
             alt />
        <img v-else
             src="@/assets/img/default_bgimg.jpg"
             alt />
        <van-cell class="examList">
          <div class="info">
            <div class="footer">
              <span class="courseName">{{ pxqdData.coursename }}</span>
              <div class="right">
                <span class="score">{{ pxqdData.coursecredit }}{{$t('toEvaluated.evaluateTxt8')}}</span>
              </div>
            </div>
            <div class="footer zhongjian">
              <span class="date">{{ pxqdData.coursetype }}</span>
            </div>
            <van-divider />
            <van-button type="info"
                        block
                        @click="showQRCode">{{$t('registrationDetails.RdTxt2')}}</van-button>
          </div>
        </van-cell>
        <div class="signLists">
          <van-list v-model="loading"
                    :finished="finished"
                    :finished-text="$t('signinTraining.StTxt2')"
                    @load="onLoad">
            <div v-for="(item, index) in list"
                 :key="index"
                 style="width: 100%">
              <van-row class="signRows"
                       type="flex">
                <van-col span="5"
                         class="signempnamecode">
                  <p class="signempname">{{ item.empname }}</p>
                  <p class="signempcode">{{ item.empcode }}</p>
                </van-col>
                <van-col span="5"
                         class="signdeptname">
                  {{
                  item.deptname
                  }}
                </van-col>
                <van-col span="14"
                         class="signstatus">
                  <p :class="[
                      item.signstatus == '正常' ? 'isSignIn' :
                      item.signstatus == '迟到' ? 'cdSignIn' :  
                      item.signstatus == '早退' ? 'zcSignIn' : 'notSignIn'
                    ]">{{ item.signstatus }}</p>
                  <p class="isSignIn"
                     v-if="item.signbt">{{$t('registrationDetails.RdTxt3')}}{{ item.signbt }}</p>
                  <p class="isSignIn"
                     v-if="item.signet">{{$t('registrationDetails.RdTxt4')}}{{ item.signet }}</p>
                </van-col>
              </van-row>
              <div class="signLine"></div>
            </div>
          </van-list>
        </div>
      </div>
    </div>
    <van-dialog v-model="show"
                title
                :showConfirmButton="false">
      <van-icon name="cross"
                @click="closeEwm" />
      <div class="title">{{ pxqdData.coursename }}</div>
      <div class="ewm">
        <qrcode :text="url+v" />
      </div>
      <p>
        {{$t('registrationDetails.RdTxt5')}}
        <br />{{$t('registrationDetails.RdTxt6')}}
      </p>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from 'vant'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
import qrcode from '@components/qrcode'
import { getSignEmpList } from '@api/wxpx.js'
import { encrypt } from '@/utils/aes.js'
export default {
  components: {
    qrcode
  },
  data () {
    return {
      userInfo,
      url: '',
      show: false,
      isLoading: false,
      list: [],
      loading: false,
      finished: false,
      pxqdData: {},
      v: '', // 随机字符
      t: null, // 定时器
      k: '' //加密字段
    }
  },
  methods: {
    onClickLeft () {
      this.$router.push('/signinTraining')
    },
    onClickRight () { },
    // 扫码
    showQRCode () {
      this.url = window.V3IsClound
        ? window.V3url +
        '/weixin/queryrpt/zhongzhuan.html?hyrptparas=|V8H5|scanSignIn|mautoid_' +
        this.$route.query.mautoid +
        '@name_' +
        encodeURI(this.pxqdData.coursename) +
        '@v_v8pcnotimestamp' +
        '|'
        : window.V3url
          ? window.V3url +
          '/weixinlocal/queryrpt/zhongzhuan.html?hyrptparas=|V8H5|scanSignIn|mautoid_' +
          this.$route.query.mautoid +
          '@name_' +
          encodeURI(this.pxqdData.coursename) +
          '@v_v8pcnotimestamp' +
          '|'
          : location.origin +
          '/scanSignIn?mautoid=' +
          this.$route.query.mautoid +
          '&name=' +
          encodeURI(this.pxqdData.coursename)
      // console.log(this.url)
      // 增加时间戳加密字段
      this.v = '&v=' + encrypt(this.k, Date.parse(new Date()).toString())
      this.show = true
      // 每10分钟刷新
      this.t = setInterval(() => {
        this.v = '&v=' + encrypt(this.k, Date.parse(new Date()).toString())
        console.log(this.v, vvvv)
      }, 1000 * 10)
    },
    closeEwm () {
      clearInterval(this.t)
      this.show = false
    },
    onLoad () {
      // 异步更新数据
      this.pxqdData = JSON.parse(window.sessionStorage.getItem('v8_pxqd_data'))
      console.log(this.pxqdData, 'this.pxqdData')
      getSignEmpList({
        mautoid: this.$route.query.mautoid,
        username: this.userInfo.username
      }).then(res => {
        this.list = res.data
        this.loading = false
        this.finished = true
      })
    }
  }
}
</script>

<style lang="less" scoped>
.registrationDetails {
  height: 100vh;
  overflow: hidden;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    overflow: auto;
    height: calc(100% - 100px);
    .registInfo {
      img {
        width: 100%;
      }
      .examList {
        padding: 32px;
        .van-cell__value {
          display: flex;
          img {
            width: 342px;
            height: 190px;
            margin-right: 18px;
          }
          .info {
            flex: 1;
            .lecturerName {
              color: #999999;
              font-size: 28px;
              font-family: Source Han Sans CN;
              font-weight: 400;
            }
            .zhongjian {
              padding-top: 10px;
            }
            .footer {
              display: flex;
              justify-content: space-between;
              // align-items: flex-end;
              align-items: baseline;
              .courseName {
                width: 70%;
                margin: 0;
                font-size: 32px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                // line-height: 54px;
                color: #333333;
                // opacity: 1;
              }
              .date {
                font-size: 28px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 24px;
                color: #999999;
              }
              .right {
                .score {
                  font-size: 32px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  line-height: 27px;
                  color: #2b8df0;
                }
                .duration {
                  font-size: 24px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  line-height: 20px;
                  color: #999999;
                }
              }
            }
            .van-button {
              padding: 0;
              height: 80px;
              border-radius: 8px;
              .van-button__text {
                font-size: 36px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 31px;
                color: #ffffff;
              }
            }
          }
        }
      }
      .signLists {
        margin-top: 20px;
        padding: 0 32px;
        background: #fff;
        .van-cell__title {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000000;
        }
        .van-cell__value {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          .isSignIn {
            color: #2b8df0;
          }
          .notSignIn {
            color: #999999;
          }
        }
      }
    }
  }
  .van-dialog {
    /deep/.van-dialog__content {
      position: relative;
      height: 100%;
      text-align: center;
      .title {
        padding-top: 0.5rem;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
      .ewm {
        width: 4.52rem;
        margin: 0 auto;
        padding-top: 1rem;
        min-height: 5.52rem;
        img {
          width: 4.52rem;
          height: 4.52rem;
        }
      }
      p {
        width: 100%;
        margin-top: 0.5rem;
      }
      .van-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 40px;
        top: 32px;
      }
    }
  }
  .signRows {
    width: 100%;
    padding: 32px 0;
    background: #fff;
    align-items: center;

    .signempnamecode {
      p {
        font-size: 24px;
        margin: 0;
      }
      .signempname {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .signempcode {
        color: #c5384c;
      }
    }
    .signdeptname {
      font-size: 24px;
      margin: 0;
      color: #000000;
      text-align: center;
    }
    .signstatus {
      p {
        width: 100%;
        font-size: 24px;
        margin: 0;
        text-align: right;
      }
      .isSignIn {
        color: #2b8df0;
      }
      .cdSignIn {
        color: #e2ab2c;
      }
      .zcSignIn {
        color: #e2702c;
      }
      .notSignIn {
        color: #999999;
      }
    }
  }
  .signLine {
    width: 100%;
    height: 2px;
    background: #ccc;
  }
}
</style>